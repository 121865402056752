import React, { useMemo } from 'react';
import { TextField } from '@mui/material';

const TimeDisplay = ({ label, seconds }) => {
  const timeString = useMemo(() => {
    const formatTime = (unit) => unit.toString().padStart(2, '0');
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hours}:${formatTime(minutes)}:${formatTime(secs)}`;
  }, [seconds]);

  return (
    <TextField
      label={label}
      value={timeString}
      variant="outlined"
      InputProps={{
        readOnly: true,
      }}
      inputProps={{
        style: {
          textAlign: "right"
        }
      }}
    />
  );
};

export default TimeDisplay;
