import React, { useState, useEffect } from "react";
import axios from "axios";
import { getUserId } from "../../lib/router";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Box,
  Divider,
  Button,
  Typography,
  Pagination
} from "@mui/material";
import {
  Add,
  CheckCircleOutline,
  ErrorOutline
} from "@mui/icons-material"
import FullScreenModal from "../../components/FullScreenModal"
import LoadingDialog from "../../components/LoadingDialog";
import GenericDialog from "../../components/GenericDialog";
import EvaluationList from "./components/EvaluationList";
import EvaluationView from "./components/EvaluationView";

const Evaluations = () => {
  const { t } = useTranslation("evaluations_new");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadingEvaluations, setLoadingEvaluations] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t("errorLoading"))

  const [evaluations, setEvaluations] = useState([]);

  const [readOnly, setReadOnly] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [template, setTemplate] = useState({ sections: [] });

  const resetState = () => {
    setTemplate({ sections: [] })
  }

  const openCreationForm = () => {
    setReadOnly(false);
    resetState();
    setDialogOpen(true);
  }

  const closeDialog = () => { setDialogOpen(false) }

  const fetchEvaluations = async (page) => {
    setLoadingEvaluations(true);
    try {
      const response = await axios.get(`/evaluation_template?page=${page}`);
      setEvaluations(response.data.templates);
      setTotalPages(response.data.pages);
      setCurrentPage(page);
    } catch (error) {
      setErrorMessage(t("errorLoading"));
      setErrorDialog(true);
    } finally {
      setLoadingEvaluations(false);
    }
  }

  const fetchFromPage = async (page) => {
    setCurrentPage(page);
    await fetchEvaluations(page);
  }

  const fetchAfterSuccess = async () => {
    setSuccessDialog(false);
    await fetchEvaluations(currentPage)
  }

  useEffect(() => {
    fetchEvaluations(1);
  }, [])

  const saveTemplate = async () => {
    setLoadingSave(true);
    const body = {
      therapistId: getUserId().userId,
      tags: [], // TOOD: Add support for tags
      sections: template.sections,
    };
    try {
      await axios.post("/evaluation_template", body);
      setSuccessDialog(true);
      resetState();
    } catch (error) {
      setErrorMessage(t("error"));
      setErrorDialog(true)
    } finally {
      setLoadingSave(false);
    }
  }

  const viewEvaluation = (index) => {
    setTemplate(evaluations[index]);
    setReadOnly(true);
    setDialogOpen(true);
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container padding={2} spacing={1} direction="column">
          <Grid item container sx={{ justifyContent: "space-between" }}>
            <Grid item>
              <Typography variant="h4" gutterBottom>
                {t("title")}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={openCreationForm}
              >
                {t("create")}
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <EvaluationList
              evaluations={evaluations}
              loading={loadingEvaluations}
              chooseEvaluation={viewEvaluation}
            />
          </Grid>
          <Grid
            item
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Pagination
                disabled={loadingEvaluations}
                count={totalPages}
                page={currentPage}
                onChange={(_e, page) => fetchFromPage(page)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <FullScreenModal
        open={dialogOpen}
        handleClose={closeDialog}
        title={readOnly ? t("view") : t("create")}
        submitButton={t("save")}
        onSubmit={saveTemplate}
        canSubmit={!readOnly}
        Content={
          <EvaluationView
            template={template}
            updateTemplate={setTemplate}
            readOnly={readOnly}
          />
        }
      />
      <LoadingDialog
        open={loadingSave}
        message={t("saving")}
      />
      <GenericDialog
        open={successDialog}
        handleClose={fetchAfterSuccess}
        icon={<CheckCircleOutline />}
        message={t("success")}
      />
      <GenericDialog
        open={errorDialog}
        handleClose={() => setErrorDialog(false)}
        icon={<ErrorOutline />}
        message={errorMessage}
      />
    </>
  )
}

export default Evaluations;