import { Box, IconButton, styled } from '@mui/material';

export const TimelineContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingLeft: theme.spacing(2.5),
  marginTop: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5)
}));

export const Timeline = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  float: 'right',
  height: '242px',
  marginBottom: theme.spacing(5.5),
  marginTop: theme.spacing(1),
  position: 'relative',
  width: '98%',
  background: theme.palette.background.paper
}));

export const TimelineBg = styled(Box)({
  height: '40px',
  position: 'relative',
  width: '100%',
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#f7f7f7'
  }
});

export const TimelineSelect = styled(Box, {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'backgroundColor'
})(({ active, backgroundColor, theme }) => ({
  alignItems: 'center',
  backgroundColor: backgroundColor,
  borderRadius: '50%',
  color: theme.palette.common.white,
  cursor: 'pointer',
  display: 'flex',
  height: '30px',
  justifyContent: 'center',
  left: '-40px',
  position: 'absolute',
  top: '5px',
  width: '30px',
  fontWeight: 400,
  fontSize: '12px',
  letterSpacing: '0.04em',
  opacity: active ? 1 : 0.6,
  pointerEvents: active ? 'inherit' : 'none',
  userSelect: 'none'
}));

export const TrashIcon = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: '-28px',
  width: '30px',
  height: '30px',
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    border: `1px solid ${theme.palette.warning.main}`,
    backgroundColor: theme.palette.warning.main,
    '& > span': {
      color: theme.palette.common.white
    }
  },
  '& > span': {
    color: theme.palette.primary.main
  }
})); 