import React, { useMemo, useCallback } from "react";

const MatrixPulse = ({ pulse, channel, index, scale, selectedPulse, selectPulse }) => {
  const seconds = useMemo(() => pulse.seconds * scale, [pulse.seconds, scale]);
  const rampL = useMemo(() => pulse.rampl / pulse.seconds, [pulse.rampl, pulse.seconds]);
  const rampR = useMemo(() => pulse.rampr / pulse.seconds, [pulse.rampr, pulse.seconds]);
  const position = useMemo(() => pulse.pos * scale, [pulse.pos, scale]);

  const handleClick = useCallback(() => {
    selectPulse(channel, pulse.arrayId);
  }, [channel, pulse.arrayId, selectPulse]);

  return (
    <div
      id={pulse.id}
      key={index}
      onClick={() => handleClick()}
      style={{
        cursor: "pointer",
        position: "absolute",
        left: `${position}px`,
        width: seconds || 30,
        height: 40,
        backgroundColor: "rgba(144,130,210,0.47)",
      }}
    >
      <div
        className="path"
        style={{
          cursor: "pointer",
          position: "absolute",
          backgroundColor: pulse.id === selectedPulse ? "#281B65" : "#9082D2",
          width: seconds || 30,
          height: 40,
          clipPath: `polygon(${rampL * 100}% 0,${rampR * 100}% 0, 100% 100%, 0 100%)`,
        }}
      ></div>
      <p
        className="noselect"
        style={{ color: "#FFF", fontSize: 10, padding: 5, position: "absolute" }}
      >
        Pulso: {index + 1}
      </p>
    </div>
  );
}

export default MatrixPulse;