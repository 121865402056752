import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Card,
  CardMedia,
  Divider,
  Grid,
  InputAdornment,
  Slider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Autocomplete
} from '@mui/material';
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "../../../store";
import QuantityInput from "../../numberInput";
import { MuscleSelector } from "../components/MuscleSelector";
import MatrixLines from "./matrixLines";
import MatrixPulse from "./matrixPulses";
import TimeDisplay from "./timeDisplay";
import {
  TimelineContainer,
  Timeline,
  TimelineBg,
  TimelineSelect,
  TrashIcon
} from './styles';

const Sequential = ({ values, data, store }) => {
  const { t } = useTranslation("sessionStudio");
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));
  const grid = useRef();

  // Values used in component rendering
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedPulse, setSelectedPulse] = useState(null);

  // Values to be sent to backend
  const [techName, setTechName] = useState("");
  const [publicName, setPublicName] = useState("");
  const [description, setDescription] = useState("");
  const [scale, setScale] = useState(1);
  const [frequency, setFrequency] = useState(35);
  const [pulseWidth, setPulseWidth] = useState(200);
  const [totalTime, setTotalTime] = useState(0);
  const [sets, setSets] = useState(1);
  const [pauseBetweenSetsMinutes, setPauseBetweenSetsMinutes] = useState(0);
  const [pauseBetweenSetsSeconds, setPauseBetweenSetsSeconds] = useState(0);
  const [reps, setReps] = useState(1);
  const [pauseBetweenReps, setPauseBetweenReps] = useState(0);
  const [matrix, setMatrix] = useState([]);
  const [channels, setChannels] = useState(
    Array.from({ length: 6 }, (_, index) => ({ ch: index + 1, name: "", index: 100, keyId: "" }))
  )
  const [channelPulses, setChannelPulses] = useState(
    Array.from({ length: 6 }, () => [])
  )
  const [musclesSelected, setMusclesSelected] = useState(
    Array.from({ length: 6 }, (_, index) => ({ ch: index + 1, img: null, showImage: false }))
  )
  const [configChannels, setConfigChannels] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const roundOneDecimal = (num) => {
    return Math.round(num * 10) / 10;
  }

  const calculateTimeString = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hours}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
  }

  const setMatrixDimensions = () => {
    if (!grid.current) return;

    let dimensions = [];
    let box = 0;
    let space = grid.current.clientWidth / 10;
    for (let i = 0; i < 9; i++) {
      box = box + space;
      dimensions.push(box);
    }
    setMatrix(dimensions);
    setScale(space);
  };

  // Initializes matrix view, makes it react to window resizes
  useEffect(() => {
    if (!grid.current) return;

    // Create ResizeObserver to watch for size changes
    const resizeObserver = new ResizeObserver(() => {
      setMatrixDimensions();
    });

    // Start observing the grid element
    resizeObserver.observe(grid.current);

    // Initial calculation
    setMatrixDimensions();

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [grid.current]); // Only re-run if grid.current changes

  // Load existing values
  useEffect(() => {
    if (data?.params && Object.keys(data.params).length > 0) {
      const { ch1, ch2, ch3, ch4, ch5, ch6,
        matrix, total, series, pausa_series,
        num_contraccion, pausa_contraccion,
        frecuenciaTemp, ancho_pulsoTemp,
        technical_name, public_name, description,
        musclesSelected, config_channels, mediaId } = data.params;
      const tempChannels = [ch1, ch2, ch3, ch4, ch5, ch6];
      tempChannels.forEach((chn) => {
        if (chn?.length) {
          chn.forEach((pulse, index) => {
            pulse.arrayId = index;
          })
        }
      })
      if (tempChannels) setChannelPulses(tempChannels);
      if (matrix) setMatrix(matrix);
      if (total) setScale(total);
      if (series) setSets(series);
      if (pausa_series) setPauseBetweenSetsMinutes(pausa_series.minutes);
      if (pausa_series) setPauseBetweenSetsSeconds(pausa_series.seconds);
      if (num_contraccion) setReps(num_contraccion);
      if (pausa_contraccion) setPauseBetweenReps(3600 * pausa_contraccion.hours + 60 * pausa_contraccion.minutes + pausa_contraccion.seconds);
      if (frecuenciaTemp) setFrequency(frecuenciaTemp);
      if (ancho_pulsoTemp) setPulseWidth(ancho_pulsoTemp);
      if (technical_name) setTechName(technical_name);
      if (public_name) setPublicName(public_name);
      if (description) setDescription(description);
      if (musclesSelected) setMusclesSelected(musclesSelected);
      if (config_channels) setConfigChannels(config_channels);
      if (mediaId) setSelectedVideo(store.videos.find(v => v._id === mediaId));
    }
  }, [data]);

  // Save values
  useEffect(() => {
    if (values) {
      values({
        channels: channelPulses.map((chn) => chn.length > 0),
        ch1: channelPulses[0],
        ch2: channelPulses[1],
        ch3: channelPulses[2],
        ch4: channelPulses[3],
        ch5: channelPulses[4],
        ch6: channelPulses[5],
        matrix,
        total: scale,
        series: sets,
        pausa_series: {
          hours: Math.floor(pauseBetweenSetsMinutes / 60),
          minutes: pauseBetweenSetsMinutes % 60,
          seconds: pauseBetweenSetsSeconds,
        },
        num_contraccion: reps,
        pausa_contraccion: {
          hours: Math.floor(pauseBetweenReps / 3600),
          minutes: Math.floor((pauseBetweenReps % 3600) / 60),
          seconds: pauseBetweenReps % 60
        },
        time: calculateTimeString(totalTime),
        frecuenciaTemp: frequency,
        frecuencia: frequency,
        ancho_pulsoTemp: pulseWidth,
        ancho_pulso: pulseWidth,
        public_name: publicName,
        technical_name: techName,
        description,
        musclesSelected,
        config_channels: configChannels,
        mediaId: selectedVideo?._id,
      })
    }
  }, [channelPulses,
    matrix,
    scale,
    sets,
    pauseBetweenSetsMinutes,
    pauseBetweenSetsSeconds,
    reps,
    pauseBetweenReps,
    frequency,
    pulseWidth,
    publicName,
    techName,
    description,
    musclesSelected,
    configChannels,
    selectedVideo])

  // Updates the total timer
  useEffect(() => {
    const pulseDuration = channelPulses.some((item) => item.length > 0) ? Math.max(...channelPulses.flat().map((item) => item.pos + item.seconds)) : 0;
    setTotalTime(
      (sets - 1) * (60 * pauseBetweenSetsMinutes + pauseBetweenSetsSeconds)
      + (sets) * ((reps - 1) * pauseBetweenReps + (reps) * pulseDuration)
    )
  },
    [sets, pauseBetweenSetsMinutes, pauseBetweenSetsSeconds, reps, pauseBetweenReps, channelPulses]
  )

  const setPulseAttribute = (value, key) => {
    const channelsCopy = [...channelPulses];
    channelsCopy[selectedChannel][selectedPulse][key] = roundOneDecimal(value);
    setChannelPulses(channelsCopy);
  };

  const setRampLeft = (value) => {
    const channelsCopy = [...channelPulses];
    let left = value < 0.5 ? 0 : value;
    if (left > 0 && left >= channelPulses[selectedChannel][selectedPulse].rampr) {
      left = channelPulses[selectedChannel][selectedPulse].rampr
      let right = value + channelPulses[selectedChannel][selectedPulse].rampr - channelPulses[selectedChannel][selectedPulse].rampl
      if (right > channelPulses[selectedChannel][selectedPulse].seconds - 0.5) {
        right = channelPulses[selectedChannel][selectedPulse].seconds
      }
      channelsCopy[selectedChannel][selectedPulse].rampr = roundOneDecimal(right);
      channelsCopy[selectedChannel][selectedPulse].both = true;
    } else if (left < channelsCopy[selectedChannel][selectedPulse].rampl) {
      channelsCopy[selectedChannel][selectedPulse].both = false;
    }
    channelsCopy[selectedChannel][selectedPulse].rampl = left;
    setChannelPulses(channelsCopy);
  };

  const setRampRight = (value) => {
    const channelsCopy = [...channelPulses];
    let right = value > channelPulses[selectedChannel][selectedPulse].seconds - 0.5 ? channelPulses[selectedChannel][selectedPulse].rampr : value;
    if (right > 0 && right <= channelPulses[selectedChannel][selectedPulse].rampl) {
      right = channelsCopy[selectedChannel][selectedPulse].rampl;
      let left = value + channelsCopy[selectedChannel][selectedPulse].rampl - channelsCopy[selectedChannel][selectedPulse].rampr
      if (left < 0.5) {
        left = 0;
      }
      channelsCopy[selectedChannel][selectedPulse].rampl = roundOneDecimal(left);
      channelsCopy[selectedChannel][selectedPulse].both = true;
    } else if (right > channelsCopy[selectedChannel][selectedPulse].rampr) {
      channelsCopy[selectedChannel][selectedPulse].both = false;
    }
    channelsCopy[selectedChannel][selectedPulse].rampr = right;
    setChannelPulses(channelsCopy);
  };

  const createPulse = (index) => {
    const pulseId = Math.random().toString(36).slice(-8);
    const tempPulses = [...channelPulses];
    tempPulses[index].push({ pos: 0, seconds: 1, rampl: 0, rampr: 1, id: pulseId, both: false, arrayId: tempPulses[index].length })
    setChannelPulses(tempPulses);
    setSelectedPulse(tempPulses[index].length - 1);
    setSelectedChannel(index);
  }

  const deletePulse = () => {
    const channelsCopy = [...channelPulses];
    channelsCopy[selectedChannel].forEach((item, index) => {
      if (selectedPulse < index) {
        item.arrayId -= 1
      }
    })
    channelsCopy[selectedChannel].splice(selectedPulse, 1);
    setChannelPulses(channelsCopy);
    setSelectedPulse(null);
    setSelectedChannel(null);
  }

  const selectPulse = (channel, id) => {
    setSelectedChannel(channel);
    setSelectedPulse(id);
  }

  return (
    <Grid
      container
      direction="row"
      padding={2}
      spacing={2}
    >
      <Grid
        item
        container
        direction="column"
        xs={12}
        md={4}
        spacing={1}
      >
        <Grid item>
          <TextField
            variant="outlined"
            label={t("tech_name")}
            value={techName}
            onChange={(e) => setTechName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            label={t("public_name")}
            value={publicName}
            onChange={(e) => setPublicName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            label={t("description")}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            minRows={4}
            maxRows={4}
          />
        </Grid>
        <Grid item>
          {musclesSelected && musclesSelected.map((item, index) => (
            <MuscleSelector
              key={index}
              index={index}
              data={item}
              musclesSelected={musclesSelected}
              setMusclesSelected={setMusclesSelected}
              channels={channels}
              setChannels={setChannels}
              configChannels={configChannels}
              setConfigChannels={setConfigChannels}
            />
          ))}
        </Grid>
        <Grid item>
          <Box>
            <Autocomplete
              fullWidth
              value={selectedVideo}
              onChange={(_, newValue) => setSelectedVideo(newValue)}
              options={store.videos}
              getOptionLabel={(option) => option?.name || ''}
              filterOptions={(options, { inputValue }) => {
                return options.filter(option => 
                  option.name.toLowerCase().includes(inputValue.toLowerCase())
                );
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option._id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("video")}
                  placeholder={t("search")}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </Box>

          {selectedVideo && (
            <Card sx={{ display: 'flex', mt: 2, bgcolor: 'grey.100' }}>
              <CardMedia
                component="img"
                sx={{ width: 150, height: 100, objectFit: 'cover' }}
                image={selectedVideo.cover || selectedVideo.src}
                alt={selectedVideo.name}
              />
              <Box sx={{ p: 2, flex: 1 }}>
                <Typography variant="subtitle1" component="div">
                  {selectedVideo.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t("duration")}: {selectedVideo.other}
                </Typography>
              </Box>
            </Card>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction={"column"}
        xs={12}
        md={8}
        spacing={1}
      >
        <Grid
          item
          container
          direction={"row"}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <TimeDisplay
              label={t("total_time")}
              seconds={totalTime}
            />
          </Grid>
          <Grid item container direction={"column"} xs={12} md={4}>
            <Grid item>
              <Typography variant="h7">
                {t("frecuency")}
              </Typography>
            </Grid>
            <Grid item>
              <Slider
                value={frequency}
                min={5}
                step={1}
                max={60}
                onChange={(e, v) => setFrequency(v)}
                valueLabelDisplay="on"
              />
            </Grid>
          </Grid>
          <Grid item container direction={"column"} xs={12} md={4}>
            <Grid item>
              <Typography variant="h7">
                {t("pulse_width")}
              </Typography>
            </Grid>
            <Grid item>
              <Slider
                value={pulseWidth}
                min={20}
                step={1}
                max={400}
                onChange={(e, v) => setPulseWidth(v)}
                valueLabelDisplay="on"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction={"row"}
          sx={{
            justifyContent: "space-between"
          }}
        >
          <Grid item xs={12} md={2}>
            <QuantityInput
              label={t("titleSeries")}
              min={1}
              max={50}
              value={sets}
              updateValue={setSets}
              hint={"1s - 50s"}
            />
          </Grid>
          {mdAndUp && <Grid item>
            <Divider orientation="vertical" />
          </Grid>}
          <Grid item xs={6} md={2}>
            <QuantityInput
              label={t("pauseSeries")}
              min={0}
              value={pauseBetweenSetsMinutes}
              updateValue={setPauseBetweenSetsMinutes}
              hint={t("timer.long.minutes")}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <QuantityInput
              label={"\u00A0"}
              min={0}
              value={pauseBetweenSetsSeconds}
              updateValue={setPauseBetweenSetsSeconds}
              hint={t("timer.long.seconds")}
            />
          </Grid>
          {mdAndUp && <Grid item>
            <Divider orientation="vertical" />
          </Grid>}
          <Grid item xs={12} md={2}>
            <QuantityInput
              label={t("num_repeats")}
              min={1}
              max={50}
              value={reps}
              updateValue={setReps}
              hint={"1s - 50s"}
            />
          </Grid>
          {mdAndUp && <Grid item>
            <Divider orientation="vertical" />
          </Grid>}
          <Grid item xs={12} md={2}>
            <QuantityInput
              label={t("pause_between_repeat")}
              min={0}
              max={60}
              value={pauseBetweenReps}
              updateValue={setPauseBetweenReps}
              hint={"1s - 60s"}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="h7">
            {t("select_at_least_one_pulse")}
          </Typography>
        </Grid>
        <Grid item>
          <TimelineContainer>
            {selectedPulse !== null && (
              <TrashIcon onClick={() => deletePulse()}>
                <span style={{ fontSize: 21 }} className="icon-trash pointer" />
              </TrashIcon>
            )}
            <Timeline ref={grid}>
              <Box sx={{ minWidth: '800px', position: 'relative' }}>
                {matrix.map((item, index) => (
                  <MatrixLines key={index} item={item} index={index} />
                ))}
                {channelPulses.map((channel, index) => (
                  <TimelineBg key={index}>
                    <TimelineSelect
                      active={musclesSelected[index].img}
                      backgroundColor={index % 2 ? "#fdad34" : "#281b65"}
                      onClick={() => createPulse(index)}
                    >
                      {`${index + 1}+`}
                    </TimelineSelect>
                    {channel.map((pulse, idx) => (
                      <MatrixPulse
                        key={pulse.id}
                        pulse={pulse}
                        channel={index}
                        index={idx}
                        scale={scale}
                        selectedPulse={selectedPulse}
                        selectPulse={selectPulse}
                      />
                    ))}
                  </TimelineBg>
                ))}
              </Box>
            </Timeline>
          </TimelineContainer>
        </Grid>
        {selectedPulse !== null && (<Grid item container direction={"row"} spacing={2}>
          <Grid item container direction={"column"} xs={12} md={3}>
            <Grid item>
              <Typography variant="h7">{t("startTime")}</Typography>
            </Grid>
            <Grid item>
              <Slider
                value={channelPulses[selectedChannel][selectedPulse].pos}
                min={0}
                step={0.1}
                max={10 - channelPulses[selectedChannel][selectedPulse].seconds}
                onChange={(e, v) => setPulseAttribute(v, "pos")}
                valueLabelDisplay="on"
              />
            </Grid>
          </Grid>
          <Grid item container direction={"column"} xs={12} md={3}>
            <Grid item>
              <Typography variant="h7">{t("durationTime")}</Typography>
            </Grid>
            <Grid item>
              <Slider
                value={channelPulses[selectedChannel][selectedPulse].seconds}
                min={0.1}
                step={0.1}
                max={10 - channelPulses[selectedChannel][selectedPulse].pos}
                onChange={(e, v) => setPulseAttribute(v, "seconds")}
                valueLabelDisplay="on"
              />
            </Grid>
          </Grid>
          <Grid item container direction={"column"} xs={12} md={3}>
            <Grid item>
              <Typography variant="h7">{t("leftRamp")}</Typography>
            </Grid>
            <Grid item>
              <Slider
                value={channelPulses[selectedChannel][selectedPulse].rampl}
                min={0}
                step={channelPulses[selectedChannel][selectedPulse].rampl > 0.5 ? 0.1 : 0.5}
                max={channelPulses[selectedChannel][selectedPulse].seconds}
                onChange={(e, v) => setRampLeft(v)}
                valueLabelDisplay="on"
              />
            </Grid>
          </Grid>
          <Grid item container direction={"column"} xs={12} md={3}>
            <Grid item>
              <Typography variant="h7">{t("rightRamp")}</Typography>
            </Grid>
            <Grid item>
              <Slider
                value={channelPulses[selectedChannel][selectedPulse].rampr}
                min={0}
                step={channelPulses[selectedChannel][selectedPulse].rampl > 0.5 ? 0.1 : 0.5}
                max={channelPulses[selectedChannel][selectedPulse].seconds}
                onChange={(e, v) => setRampRight(v)}
                valueLabelDisplay="on"
              />
            </Grid>
          </Grid>
        </Grid>)}
      </Grid>
    </Grid>
  )
}

export default connect(Sequential);;