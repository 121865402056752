import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Button } from "@mui/material";
import { Add, Delete } from "@mui/icons-material"

const SelectionTemplate = ({
  params,
  updateParams,
  readOnly = false
}) => {
  const { t } = useTranslation("evaluations_new");

  const setOptionText = (value, index) => {
    const paramsCopy = [...params];
    paramsCopy[index].text = value;
    updateParams(paramsCopy);
  }

  const addNewOption = () => {
    const paramsCopy = [...params];
    paramsCopy.push({
      text: (paramsCopy.length + 1).toString(),
      value: paramsCopy.length
    })
    updateParams(paramsCopy);
  }

  const removeLastOption = () => {
    const paramsCopy = [...params];
    paramsCopy.pop();
    updateParams(paramsCopy);
  }

  return (
    <>
      <Grid container padding={2} direction="column">
        {params.map((item, index) => (
          <Grid
            key={index}
            item
            container
            padding={2}
            spacing={2}>
            <Grid
              item
              container
              xs={1}
              sx={{ alignItems: "center", }}
            >
              <Grid item>
                {index + 1}.-
              </Grid>
            </Grid>
            <Grid item xs={11}>
              <TextField
                disabled={readOnly}
                variant="outlined"
                label={t("template.activity.option")}
                value={item.text}
                onChange={(e) => setOptionText(e.target.value, index)}
                fullWidth={true}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      {!readOnly && (<Grid
        container
        padding={2}
        spacing={2}
        sx={{
          justifyContent: "flex-end"
        }}>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={addNewOption}
          >
            {t("template.activity.optionAdd")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<Delete />}
            onClick={removeLastOption}
            disabled={params.length < 3}
          >
            {t("template.activity.optionDelete")}
          </Button>
        </Grid>
      </Grid>)}
    </>
  )
}

export default SelectionTemplate;