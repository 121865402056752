import React, { Component } from "react";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
} from "trainfes-components-library";
import { connect } from "../../store";

import { functionsData } from "./helpers/functionsData";
import { validations } from "./helpers/functionsValidations";
import { DaD } from "./dragAndDrop";

import ModalModos from "../../components/modalExtended";

import CardLayoutPlatform from "../../components/CardLayoutPlatform";
import { RowTraining } from "./components/RowTraining";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import * as S from "./sessionsStudio.styles";
import {
  Box,
  TimeContainer,
  TimeText,
  TimeTotal,
} from "../../components/modos/styles/Modos.styles";

// TODO: Ideal borrar estas 2 importaciones
import "../../assets/css/views/therapistStyle.css";
import "../../assets/img/icomoon/style.css";
import {
  getSeconds,
  getSecondsFromString,
  secondsToDate,
  hoursToSeconds,
  minutesToSeconds
} from "../../lib/modes.helpers";
import TrainingForm from "./trainingForm.jsx";

var self = null;
var dataModel = null;

class SessionsStudio extends Component {
  constructor() {
    super();

    this.state = {
      sessionId: "",
      protocol: "",
      dataFunc: null,
      functions: [],
      flux: [],
      active: null,
      position: null,
      open: false,
      modeModal: "",
      data: null,
      desc: "",
      addsensor: "",
      name: "",

      itemToDelete: null,
      showDeleteModal: false,

      total_time: "00:00:00",
    };

    this.container = React.createRef();
    this.icon = React.createRef();
    this.text = React.createRef();
  }

  componentDidMount() {
    self = this;
    this.props.setActivePromp(false);
    const { t, match } = this.props;
    const translateTraining = (lang) => "Entrenamiento con " + t(lang);
    const translateBlock = (lang) => "bloque de " + t(lang);

    this.setState({
      functions: functionsData(t, translateBlock, translateTraining),
    });

    Axios.get("/training/get/session/" + match.params.id)
      .then((res) => {
        this.setState({
          flux: res.data.data.trainings,
          protocol: res.data.data.protocol,
          sessionId: res.data.data._id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  changeBackgroundColor(open) {
    if (open) {
      this.container.current.style.background = "#EEF2FF";
      if (this.icon.current != null) {
        this.icon.current.style.color = "#fff";
        this.text.current.style.color = "#fff";
      }
    } else {
      if (this.container.current) {
        this.container.current.style.background = "#fff";
      }
      if (this.icon.current != null) {
        this.icon.current.style.color = "#ccc";
        this.text.current.style.color = "#ccc";
      }
    }
  }

  remove(item) {
    this.state.flux.splice(
      this.state.flux.findIndex((i) => i.id === item.id),
      1
    );
    this.setState({ flux: this.state.flux });

    this.setState({ itemToDelete: null });
    this.setState({ showDeleteModal: false });
  }

  openParams(data, typeAction = "create") {
    data["typeAction"] = typeAction;
    data["type"] = typeAction;
    dataModel = null;
    this.setState({ data: data, open: true, modeModal: data.mode });
  }

  createTraining() {
    const form = new FormData();
    const { t } = this.props;

    form.append("trainings", JSON.stringify(this.state.flux));

    // * Imprime en consola la data que se envía.
    // for (var pair of form.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    Axios.post("/training/trainings/" + this.props.match.params.id, form)
      .then((data) => {
        this.props.setActivePromp(false);
        this.props.history.block(() => { })
        if (data.data.success) {
          this.props.notify({
            type: "success",
            title: "SessionStudio",
            text: t("successSession"),
          });

          let { protocol, sessionId } = this.state;

          this.props.history.push(
            `/content/protocols/${protocol}/${sessionId}`
          );
        }
      })
      .catch(() =>
        this.props.notify({
          type: "error",
          title: "SessionStudio",
          text: t("errSession"),
        })
      );
  }

  append(data) {
    this.props.setActivePromp(true);
    data["status"] = "modified";
    let _flux = Object.assign([], this.state.flux);

    if (data.typeAction === "create" || data.type === "create")
      _flux.push(data);

    if (data.typeAction === "edit" || data.type === "edit") {
      _flux = _flux.map((e) => {
        if (e.id === data.id) return { ...e, ...data };
        else return e;
      });
    }

    this.setState({ flux: _flux });
    this.setState({ open: false });
  }

  saveModAndCreate() {
    const { t } = this.props;
    const data = this.state.data;

    if (validations[data.mode](dataModel, this.props.notify, t)) {
      data["params"] = dataModel;
      data["name"] = dataModel["name"];
      data["addsensor"] = dataModel["addsensor"];
      data["desc"] = dataModel["description"];
      data["files"] = dataModel["files"];
      if (data.mode === "video") data["videos"] = dataModel["videos"];
      this.append(data);
      this.setState({ open: false });
    }
  }

  /**
   * Method to save and create a new training with the new components configuration.
   * It assumes that the validation has been done before.
   * Why not use the append method? Because it is a refactor transition.
   * @param {Object} trainningData Data of the new training to add to the session.
   */
  saveModAndCreate2 = (trainningData) => {
    this.append(trainningData);
  }

  titleModal(mode) {
    for (let i = 0; i < this.state.functions.length; i++)
      if (this.state.functions[i].mode === mode)
        return this.state.functions[i].name;
  }

  renderMode() {
    console.log("sessionsStudio renderMode", this.state.modeModal);
    if (this.state.modeModal !== "") {
      const Comp =
        require(`../../components/modos/${this.state.modeModal}`).default;
      if (Comp)
        return (
          <Comp
            values={(value) => (dataModel = value)}
            data={JSON.parse(JSON.stringify(this.state.data))}
          />
        );
      else return null;
    }
  }

  infoDragAndDrop() {
    const { t } = this.props;
    return (
      <div style={{ width: "100%", height: 200, position: "relative" }}>
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            margin: "0 auto",
            top: "250px",
            width: 400,
            textAlign: "center",
          }}
        >
          <span
            ref={this.icon}
            style={{ fontSize: 35 }}
            className="material-icons color-secundario"
          >
            touch_app
          </span>
          <p
            className="color-primario"
            ref={this.text}
            style={{ fontSize: 14 }}
          >
            {t("dragBlock")}
          </p>
        </div>
      </div>
    );
  }

  drop(data, target) {
    if (data["type"] === "create") {
      self.openParams(data, "create");
    }

    if (data["type"] === "move") {
      const _flux = Object.assign([], self.state.flux);
      const to = _flux.findIndex((e) => e.id === target.id);
      const from = _flux.findIndex((e) => e.id === data.id);
      if (to >= 0 && from >= 0) {
        const _from = _flux.splice(from, 1);
        _flux.splice(to, 0, _from[0]);
        self.setState({ flux: _flux });
      }
    }
  }

  convertir(segundosP) {
    const segundos = Math.round(segundosP % 0x3c).toString();
    const s =
      segundos <= 0
        ? "00"
        : segundos < 10
          ? "0" + segundos
          : segundos.toString();
    const horas = Math.floor(segundosP / 0xe10);
    const h = horas <= 0 ? "00" : horas < 10 ? "0" + horas : horas.toString();
    const minutos = (Math.floor(segundosP / 0x3c) % 0x3c).toString();
    const m =
      minutos <= 0 ? "00" : minutos < 10 ? "0" + minutos : minutos.toString();
    return `${h}:${m}:${s}`;
  }

  getTotalTime() {
    if (!this.state.flux.length > 0) return;
    let result = 0;

    this.state.flux.forEach((item) => {
      if (item.params.time) return result += getSecondsFromString(item.params.time);

      if (item.params.tiempo_trabajo) {
        return result += getSeconds(item.params.tiempo_trabajo);
      }
    });

    return secondsToDate(result)
  }

  handleModalOnClose() {
    self.setState({ open: false });
  }

  handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(self.state.flux);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    self.setState({ flux: items });
  }

  handleShowDeleteModal(item) {
    self.setState({ itemToDelete: item });
    self.setState({ showDeleteModal: true });
  }

  handleCloseDeleteModal() {
    self.setState({ itemToDelete: null });
    self.setState({ showDeleteModal: false });
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <Container>
          <Row cols={12} gap={30}>
            <Col xs={12}>
              <CardLayoutPlatform
                title={t("workflow")}
                padding={"0"}
                noPaddingBottom
              >
                <S.RowStyled>
                  <S.ModesContainer>
                    {this.state.functions.map((e, i) => {
                      let isDisable =
                        e.mode === "treadmill" || e.mode === "cicloergometro";
                      return (
                        <S.Block
                          key={i}
                          id={`function--block_${e.mode}`}
                          className={`${isDisable ? "__block-disable" : ""}`}
                          onDragStart={(ev) =>
                            DaD.onDragStart(ev, e, "create", () =>
                              this.changeBackgroundColor(true)
                            )
                          }
                          draggable={true}
                          onDragEnd={(ev) =>
                            DaD.onDragEnd(ev, () =>
                              this.changeBackgroundColor(false)
                            )
                          }
                        >
                          <div className="block--icon_sensor">
                            {e.addsensor}
                          </div>
                          <S.BlockTitle>{e.name}</S.BlockTitle>
                          <S.Icon>{e.iconSVG}</S.Icon>
                        </S.Block>
                      );
                    })}
                  </S.ModesContainer>
                  <S.HeaderContainer>
                    <S.Header>
                      <TimeContainer>
                        <TimeTotal>{t("total_time")}:</TimeTotal>
                        <Box padding={"10px"}>
                          <TimeText>
                            {this.getTotalTime() || "00:00:00"}
                          </TimeText>
                        </Box>
                      </TimeContainer>
                      <Button
                        label={t("save")}
                        width="155px"
                        onClick={() => this.createTraining()}
                      />
                    </S.Header>
                    <DragDropContext onDragEnd={this.handleOnDragEnd}>
                      <div
                        ref={this.container}
                        onDrop={(ev) => DaD.onDrop(ev, this.drop)}
                        onDragOver={(ev) => DaD.onDragOver(ev)}
                        id="function--fluxContainer"
                      >
                        <Droppable droppableId="boxes">
                          {(provided) => (
                            <S.FluxContainer
                              className="boxes"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {this.state.flux.length > 0 ? (
                                <>
                                  {this.state.flux.map((e, i) => {
                                    return (
                                      <Draggable
                                        key={e.id}
                                        draggableId={e.id}
                                        index={i}
                                      >
                                        {(provided) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <RowTraining
                                              key={i}
                                              className="box"
                                              isNew={
                                                e.status &&
                                                e.status === "modified"
                                              }
                                              t={t}
                                              id={e.id}
                                              index={i + 1}
                                              data={e}
                                              edit={() =>
                                                this.openParams(e, "edit")
                                              }
                                              remove={() =>
                                                this.handleShowDeleteModal(e)
                                              }
                                              hoursToSeconds={
                                                hoursToSeconds
                                              }
                                              minutesToSeconds={
                                                minutesToSeconds
                                              }
                                              convertir={this.convertir}
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </>
                              ) : (
                                this.infoDragAndDrop()
                              )}
                            </S.FluxContainer>
                          )}
                        </Droppable>
                      </div>
                    </DragDropContext>
                  </S.HeaderContainer>
                </S.RowStyled>
              </CardLayoutPlatform>
            </Col>
          </Row>
        </Container>

        <Modal
          title={`${t("sure_you_want_to_delete")} ${this.state.itemToDelete
            ? this.state.itemToDelete.params.technical_name
            : ""
            }?`}
          isOpen={this.state.showDeleteModal}
          buttons={
            <>
              <Button
                type="ghost"
                label={t("sessionStudio:cancel")}
                onClick={() => this.handleCloseDeleteModal()}
              />
              <Button
                label={t("sessionStudio:delete")}
                onClick={() => this.remove(this.state.itemToDelete)}
              />
            </>
          }
        >
          <p>{t("sessionStudio:changes_will_be_reflected")}</p>
        </Modal>

        {["graphic", "video", "general"].includes(this.state.modeModal) ?
          <TrainingForm
            open={this.state.open}
            setOpen={(value) => this.setState({ open: value })}
            handleClose={this.handleModalOnClose}
            onSubmit={this.saveModAndCreate2}
            data={this.state.data}
          />
          : <ModalModos
            open={this.state.open}
            setOpen={(value) => this.setState({ open: value })}
            onClose={this.handleModalOnClose}
            callback={() => this.saveModAndCreate()}
            title={t("parameters") + " " + this.titleModal(this.state.modeModal)}
            btnText={t("save")}
          >
            {this.renderMode()}
          </ModalModos>}
      </div>
    );
  }
}

export default withTranslation("sessionStudio")(
  connect(withRouter(SessionsStudio))
);
