import React from "react";

const MatrixLines = ({ item, index }) => {
  return (
    <div
      key={index + 1}
      style={{
        position: "absolute",
        left: `${item}px`,
        width: 1,
        height: 240,
        background: "#F0F0F0",
        zIndex: 1000,
      }}
    >
      <p
        className="noselect"
        style={{ position: "absolute", bottom: -45, left: -5, color: "#CCC" }}
      >
        {index + 1}
      </p>
    </div>
  )
}

export default MatrixLines;