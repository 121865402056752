import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField, } from "@mui/material";

const RangeTemplate = ({
  params,
  updateParams,
  readOnly = false,
}) => {
  const { t } = useTranslation("evaluations_new");

  const setRangeLimit = (limit, value) => {
    const paramsCopy = [...params];
    paramsCopy[0].value[limit] = parseInt(value, 10);
    updateParams(paramsCopy);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={readOnly}
          variant="outlined"
          label={t("template.activity.minimumValue")}
          value={params[0].value.min}
          onChange={(e) => setRangeLimit("min", e.target.value)}
          fullWidth={true}
          type="number"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={readOnly}
          variant="outlined"
          label={t("template.activity.maximumValue")}
          value={params[0].value.max}
          onChange={(e) => setRangeLimit("max", e.target.value)}
          fullWidth={true}
          type="number"
        />
      </Grid>
    </Grid>
  )
}

export default RangeTemplate;