import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import TimePicker from "../../timePicker";
import Counter from "../../counter";
import { useTranslation } from "react-i18next";
import SearchIcon from '@mui/icons-material/Search';
import {
  TextField,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Card,
  CardMedia,
  InputAdornment,
  Autocomplete
} from "@mui/material";
import { getSeconds, secondsToDate } from "../../../lib/modes.helpers";
import { timeDisplayStyles, tabStyles } from "./styles";
import WorkIntensitySlider from './WorkIntensitySlider';
import { connect } from "../../../store";

const General = ({ data, values, store }) => {
  const { t } = useTranslation("sessionStudio");
  
  // Basic info state
  const [basicInfo, setBasicInfo] = useState({
    technical_name: "",
    public_name: "",
    description: "",
  });

  // Time-related state
  const [timeConfig, setTimeConfig] = useState({
    tiempo_trabajo: { hours: 0, minutes: 0, seconds: 0 },
    time: "00:00:00",
    tab_active: "0",
  });

  // Series and repetitions state
  const [seriesConfig, setSeriesConfig] = useState({
    repeticiones: null,
    series: null,
    pausa_repeticiones: { hours: 0, minutes: 0, seconds: 0 },
    pausa_series: { hours: 0, minutes: 0, seconds: 0 },
  });

  // Work intensity state
  const [workIntensity, setWorkIntensity] = useState(0);

  // Files and channels state (less frequently updated)
  const [filesAndChannels, setFilesAndChannels] = useState({
    files: [],
    channels: [],
    config_channels: [],
  });

  // Metadata state (rarely updated)
  const [metadata, setMetadata] = useState({
    name: "",
    icon: "icon-alert",
    video: "",
    type: true,
  });

  // Video selection state
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    if (data.params && Object.keys(data.params).length !== 0) {
      const {
        name,
        series,
        pausa_series,
        pausa_repeticiones,
        repeticiones,
        files,
        description,
        technical_name,
        public_name,
        work_intensity,
        tiempo_trabajo,
        time,
        tab_active,
        mediaId
      } = data.params;

      setBasicInfo({
        technical_name: technical_name || name || "",
        public_name: public_name || "",
        description: description || "",
      });

      setTimeConfig(prev => ({
        ...prev,
        tiempo_trabajo: tiempo_trabajo || prev.tiempo_trabajo,
        time: time || "00:00:00",
        tab_active: tab_active || "0",
      }));

      setSeriesConfig({
        repeticiones,
        series,
        pausa_repeticiones,
        pausa_series,
      });

      setWorkIntensity(work_intensity || 0);

      setFilesAndChannels(prev => ({
        ...prev,
        files,
      }));

      setMetadata(prev => ({
        ...prev,
        name,
      }));

      if (mediaId && store?.videos) {
        setSelectedVideo(store.videos.find(v => v._id === mediaId));
      }
    }
  }, [data.params, store?.videos]);

  useEffect(() => {
    if (values !== undefined) {
      // Combine all states into a single object for the parent component
      const combinedState = {
        ...basicInfo,
        ...timeConfig,
        ...seriesConfig,
        work_intensity: workIntensity,
        ...filesAndChannels,
        ...metadata,
        mediaId: selectedVideo?._id,
      };
      values(combinedState);
    }
  }, [basicInfo, timeConfig, seriesConfig, workIntensity, filesAndChannels, metadata, values, selectedVideo]);

  /**
   * Calculates and updates the total time based on the current configuration:
   * - In repeat mode (tab_active === "0"): Sums up the pause times between repetitions and series
   * - In time mode (tab_active === "1"): Uses the direct work time input
   * 
   * The calculation considers:
   * - Pause between repetitions * number of repetitions
   * - Pause between series * number of series
   * - Direct work time (when in time mode)
   * 
   * Updates the timeConfig.time with the formatted total time
   */
  useEffect(() => {
    const totalSeconds =
      getSeconds(seriesConfig.pausa_repeticiones) * seriesConfig.repeticiones +
      getSeconds(seriesConfig.pausa_series) * seriesConfig.series;

    const totalSecondsWorkTime = getSeconds(timeConfig.tiempo_trabajo);

    const total = timeConfig.tab_active === "0" ? totalSeconds : totalSecondsWorkTime;
    setTimeConfig(prev => ({
      ...prev,
      time: secondsToDate(total),
    }));
  }, [timeConfig.tab_active, seriesConfig.pausa_repeticiones, seriesConfig.pausa_series, seriesConfig.series, seriesConfig.repeticiones, timeConfig.tiempo_trabajo]);

  const updateBasicInfo = (key, value) => {
    setBasicInfo(prev => ({ ...prev, [key]: value }));
  };

  const setTabActive = (val) => {
    setTimeConfig(prev => ({ ...prev, tab_active: val }));
  };

  const updateSeriesConfig = (key, value) => {
    setSeriesConfig(prev => ({ ...prev, [key]: value }));
  };

  const updateTimeConfig = (key, value) => {
    setTimeConfig(prev => ({ ...prev, [key]: value }));
  };

  return (
    <Grid container padding={2} spacing={2}>
      {/* Left Column */}
      <Grid item xs={6}>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            required
            label={t("tech_name")}
            value={basicInfo.technical_name}
            onChange={({ target }) => updateBasicInfo("technical_name", target.value)}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label={t("public_name")}
            value={basicInfo.public_name}
            onChange={({ target }) => updateBasicInfo("public_name", target.value)}
          />
        </Box>

        {/* Time Display Section */}
        <Box sx={timeDisplayStyles.container}>
          <Typography variant="body2" sx={timeDisplayStyles.label}>
            {t("total_time")}:
          </Typography>
          <Box sx={timeDisplayStyles.timeBox}>
            <Typography variant="body1" sx={timeDisplayStyles.timeValue}>
              {timeConfig.time}
            </Typography>
          </Box>
        </Box>

        {/* Tabs Section */}
        <Box sx={tabStyles.container}>
          <RadioGroup
            row
            value={timeConfig.tab_active}
            onChange={(e) => {
              setTabActive(e.target.value);
            }}
          >
            <Box sx={tabStyles.radioContainer}>
              <FormControlLabel
                value="0"
                control={<Radio sx={tabStyles.radioLabel} />}
                label={t("repeat")}
              />
            </Box>
            <Box sx={{ ...tabStyles.radioContainer, ml: 1 }}>
              <FormControlLabel
                value="1"
                control={<Radio sx={tabStyles.radioLabel} />}
                label={t("time")}
              />
            </Box>
          </RadioGroup>
        </Box>

        {/* Series and Time Controls */}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {timeConfig.tab_active === "0" ? (
              <Box sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("series")}
                </Typography>
                <Counter
                  title="0 - 100"
                  min={0}
                  max={100}
                  data={seriesConfig.series}
                  values={(value) => updateSeriesConfig("series", value)}
                />

                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    {t("repeatSeries")}
                  </Typography>
                  <Counter
                    title="0 - 50"
                    min={0}
                    max={50}
                    data={seriesConfig.repeticiones}
                    values={(value) => updateSeriesConfig("repeticiones", value)}
                  />
                </Box>
              </Box>
            ) : (
              <Box sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("timeOfWork")}
                </Typography>
                <TimePicker
                  data={timeConfig.tiempo_trabajo}
                  seconds={true}
                  minutes={true}
                  values={(value) => updateTimeConfig("tiempo_trabajo", value)}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={6}>
            {timeConfig.tab_active === "0" && (
              <>
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    {t("pauseSeries")}
                  </Typography>
                  <TimePicker
                    data={seriesConfig.pausa_series}
                    seconds={true}
                    minutes={true}
                    values={(value) => updateSeriesConfig("pausa_series", value)}
                  />
                </Box>

                <Box sx={{ p: 2, mt: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    {t("between_repeat")}
                  </Typography>
                  <TimePicker
                    data={seriesConfig.pausa_repeticiones}
                    seconds={true}
                    minutes={true}
                    values={(value) => updateSeriesConfig("pausa_repeticiones", value)}
                  />
                </Box>
              </>
            )}
          </Grid>

        {/* Video Selection */}
        <Grid item xs={12}>
        <Box sx={{ mt: 4 }}>
          <Autocomplete
            fullWidth
            value={selectedVideo}
            onChange={(_, newValue) => setSelectedVideo(newValue)}
            options={store?.videos || []}
            getOptionLabel={(option) => option?.name || ''}
            filterOptions={(options, { inputValue }) => {
              return options.filter(option => 
                option.name.toLowerCase().includes(inputValue.toLowerCase())
              );
            }}
            renderOption={(props, option) => (
              <li {...props} key={option._id}>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("video")}
                placeholder={t("search")}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        </Box>

        {selectedVideo && (
          <Card sx={{ display: 'flex', mt: 2, bgcolor: 'grey.100' }}>
            <CardMedia
              component="img"
              sx={{ width: 150, height: 100, objectFit: 'cover' }}
              image={selectedVideo.cover || selectedVideo.src}
              alt={selectedVideo.name}
            />
            <Box sx={{ p: 2, flex: 1 }}>
              <Typography variant="subtitle1" component="div">
                {selectedVideo.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("duration")}: {selectedVideo.other}
              </Typography>
            </Box>
          </Card>
        )}
        </Grid>
        </Grid>
      </Grid>

      {/* Right Column */}
      <Grid item xs={6}>
        <TextField
          label={t("description")}
          fullWidth
          multiline
          rows={4}
          value={basicInfo.description}
          onChange={({ target }) => updateBasicInfo("description", target.value)}
        />

        <Box sx={{ mt: 6 }}>
          <WorkIntensitySlider
            value={workIntensity}
            onChange={(value) => setWorkIntensity(value)}
            t={t}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

General.propTypes = {
  data: PropTypes.shape({
    params: PropTypes.object
  }),
  values: PropTypes.func,
  store: PropTypes.object
};

export default connect(General); 