import Search from "@mui/icons-material/Search";
import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { trackWindowScroll } from "react-lazy-load-image-component";
import LinesEllipsis from "react-lines-ellipsis";
import "../../../../src/assets/css/video.css";
import { FilterIcon } from "../../../assets/icons";
import {
  getSecondsFromString,
  secondsToDate,
} from "../../../lib/modes.helpers";
import { connect } from "../../../store";
import { SidebarTitle } from "../../CardLayoutPlatform/styles";
import { FileCard } from "../components/FileCard";
import FilterSidebar from "../components/FilterSidebar";
import { SidebarContainerStyled } from "../records";
import * as M from "../styles/Modos.styles";

const Video = ({ store, values, data, scrollPosition }) => {
  const { t } = useTranslation("sessionStudio");

  const [videos, setVideos] = useState([]);
  const [filter, setFilter] = useState("");
  const [technicalName, setTechnicalName] = useState("");
  const [publicName, setPublicName] = useState("");
  const [description, setDescription] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);
  const [filterCategory, setFilterCategory] = useState("");
  const [filterDevice, setFilterDevice] = useState("");
  const [time, setTime] = useState("");

  const handleTotalTime = useCallback(() => {
    const totalSeconds = videos.reduce(
      (acc, video) => acc + getSecondsFromString(video.other),
      0
    );
    const formattedTime = secondsToDate(totalSeconds);
    setTime(formattedTime);
    return formattedTime;
  }, [videos]);

  useEffect(() => {
    if (data?.params) {
      const { videos, technical_name, public_name, description, time } = data.params;

      if (time) setTime(time);
      if (videos) setVideos(videos);
      if (technical_name) setTechnicalName(technical_name);
      if (public_name) setPublicName(public_name);
      if (description) setDescription(description);
    }
  }, [data]);

  useEffect(() => {
    if (values) {
      values({
        videos: videos.map((video) => ({ ...video, cover: "" })),
        time,
        public_name: publicName,
        technical_name: technicalName,
        description,
      });
    }
  }, [videos, time, publicName, technicalName, description, values]);

  const handleRemove = (id) => {
    setVideos((prevVideos) =>
      prevVideos.filter((video) => video._id !== id)
    );
  };

  const handleClick = (card) => {
    setVideos((prevVideos) => {
      const exists = prevVideos.some((video) => video._id === card._id);
      return exists
        ? prevVideos.filter((video) => video._id !== card._id)
        : [card, ...prevVideos];
    });
    handleTotalTime();
  };

  const handleCategoryFilter = (item) =>
    !filterCategory || item.cat._id === filterCategory.value;

  const handleDeviceFilter = (item) =>
    !filterDevice || item.device._id === filterDevice.value;

  return (
    <M.ScreenContainer>
      <M.RecordsVideoForm>
        <div>
          <TextField
            fullWidth
            required
            label={t("tech_name")}
            variant="outlined"
            value={technicalName}
            onChange={(e) => setTechnicalName(e.target.value)}
          />
          <TextField
            fullWidth
            label={t("public_name")}
            variant="outlined"
            value={publicName}
            onChange={(e) => setPublicName(e.target.value)}
          />
        </div>
        <TextField
          label={t("description")}
          variant="outlined"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </M.RecordsVideoForm>

      <M.ContainerBody>
        <M.SidePanel>
          {videos.map((file, i) => (
            <div key={i} className="__video">
              <div className="__info">
                <span>
                  <LinesEllipsis
                    text={file.name}
                    maxLine="3"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </span>
                <div>
                  <span className="__serie">{t("duration")}</span>
                  <hr />
                  <span className="__time">{file.other}</span>
                </div>
              </div>
              <M.CloseStyled onClick={() => handleRemove(file._id)} />
            </div>
          ))}
        </M.SidePanel>

        <M.DataBody>
          <SidebarContainerStyled
            showSidebar={showSidebar}
            onClick={() => setShowSidebar(false)}
          >
            <div
              className="cardlayout--sidebar"
              onClick={(e) => e.stopPropagation()}
            >
              <SidebarTitle>
                <FilterIcon /> {t("filters")}
              </SidebarTitle>
              <FilterSidebar
                t={t}
                filterCategory={filterCategory}
                setFilterCategory={setFilterCategory}
                filterDevice={filterDevice}
                setfilterDevice={setFilterDevice}
                setShowSidebar={setShowSidebar}
              />
            </div>
          </SidebarContainerStyled>

          <div className="__header">
            <TextField
              fullWidth
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              placeholder={t("search")}
              variant="outlined"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <Button
              variant="outlined"
              startIcon={<FilterIcon />}
              onClick={() => setShowSidebar(true)}
            >
              {t("filters")}
            </Button>
          </div>

          <div className="__body">
            {[...store.videos]
              .filter(
                (video) =>
                  video.name.toLowerCase().includes(filter.toLowerCase()) ||
                  !filter
              )
              .filter(handleDeviceFilter)
              .filter(handleCategoryFilter)
              .map((card, i) => (
                <FileCard
                  key={i}
                  t={t}
                  scrollPosition={scrollPosition}
                  file={card}
                  list={videos}
                  type={card.type}
                  img={card.type === 0 ? card.cover : card.src}
                  name={card.name}
                  videoDuration={card.type === 0 ? card.other : null}
                  onClick={() => handleClick(card)}
                />
              ))}
          </div>
        </M.DataBody>
      </M.ContainerBody>
    </M.ScreenContainer>
  );
};

export default connect(trackWindowScroll(Video));
