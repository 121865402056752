import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ColoredSlider from '../../ColoredSlider';
import { workIntensityStyles } from './styles';

const workIntensityMarks = [
    {
        value: 0,
        label: '0%',
        color: '#39C4A5', // green
    },
    {
        value: 60,
        label: '60%',
        color: '#FDAD34', // yellow
    },
    {
        value: 75,
        label: '75%',
        color: '#D11F1F', // red
    },
    {
        value: 100,
        label: '100%',
        color: '#D11F1F', // red
    },
];

const WorkIntensitySlider = ({ value, onChange, t }) => {
    return (
        <Box sx={workIntensityStyles.outerBox}>
            <Box sx={workIntensityStyles.container}>
                <Typography variant="subtitle2">
                    {t("work_intensity")} (0 - 100%)
                </Typography>
                <Tooltip
                    title={
                        <Typography variant="body2">
                            <strong>{t("by_FC")}</strong> <br /> {t("it_is_required_to_know_the_age")}
                        </Typography>
                    }
                    arrow
                    placement="left"
                    componentsProps={{
                        popper: {
                            sx: {
                                zIndex: 10000
                            }
                        }
                    }}
                >
                    <IconButton size="small" sx={workIntensityStyles.tooltip}>
                        <InfoIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Box>

            <Typography variant="h6" sx={{ color: 'primary.main', my: 1 }}>
                {value}
            </Typography>

            <Box sx={{ mt: 1 }}>
                <ColoredSlider
                    aria-label={t("work_intensity")}
                    min={0}
                    step={1}
                    max={100}
                    marks={workIntensityMarks}
                    value={value}
                    onChange={(_, newValue) => onChange(newValue)}
                    valueLabelDisplay="auto"
                />
            </Box>
        </Box>
    );
};

WorkIntensitySlider.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default WorkIntensitySlider; 