import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Paper,
  Divider,
  TextField,
  MenuItem,
  IconButton
} from "@mui/material";
import { Delete } from "@mui/icons-material"
import SelectionTemplate from "./SelectionTemplate";
import RangeTemplate from "./RangeTemplate";

const BlockTemplate = ({
  block,
  updateBlock,
  sectionIndex,
  blockIndex,
  removeBlock,
  canDelete = false,
  readOnly = false,
}) => {
  const { t } = useTranslation("evaluations_new");

  const SELECT_VALUES = [
    {
      text: t("template.activity.types.text"),
      value: "TEXTQUESTION"
    },
    {
      text: t("template.activity.types.number"),
      value: "NUMBER"
    },
    {
      text: t("template.activity.types.single"),
      value: "SINGLE"
    },
    {
      text: t("template.activity.types.multiple"),
      value: "MULTIPLE"
    },
    {
      text: t("template.activity.types.range"),
      value: "RANGE"
    },
    {
      text: t("template.activity.types.slider"),
      value: "SLIDER"
    }
  ]

  const [type, setType] = useState(block?.type || "TEXTQUESTION");
  const [title, setTitle] = useState(block?.title || "");
  const [description, setDescription] = useState(block?.description || "");
  // const [required, setRequired] = useState(block?.required || true);
  // const [media, setMedia] = useState(block?.media || []);
  const [params, setParams] = useState(block?.params || [{ value: 50 }]);

  useEffect(() => {
    updateBlock(type, title, description, params, sectionIndex, blockIndex);
  }, [type, title, description, params])

  const setDefaultParams = (newType) => {
    if (newType === "TEXTQUESTION") {
      setParams([{ value: 50, }])
    } else if (newType === "NUMBER") {
      setParams([{ value: { min: 0, max: 100 } }])
    } else if (["SINGLE", "MULTIPLE"].includes(newType)) {
      setParams([
        { text: "1", value: 1 },
        { text: "2", value: 2 }
      ])
    } else if (["RANGE", "SLIDER"].includes(newType)) {
      setParams([
        { value: { min: 1, max: 5, step: 1 } }
      ])
    }
    setType(newType)
  };

  return (
    <Grid item>
      <Paper
        elevation={3}
        square={false}
      >
        {!readOnly && (<Grid container padding={2} sx={{ justifyContent: "flex-end" }}>
          <IconButton
            disabled={!canDelete}
            onClick={() => removeBlock(sectionIndex, blockIndex)}
          >
            <Delete />
          </IconButton>
        </Grid>)}
        <Grid container padding={2} spacing={2}>
          <Grid item md={8} sm={12}>
            <TextField
              disabled={readOnly}
              variant="outlined"
              label={t("template.activity.title")}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth={true}
            />
          </Grid>
          <Grid item md={4} sm={12}>
            <TextField
              disabled={readOnly}
              select
              label={t("template.activity.type")}
              value={type}
              onChange={(e) => setDefaultParams(e.target.value)}
              fullWidth={true}
            >
              {SELECT_VALUES.map((item, idx) => {
                return (
                  <MenuItem
                    key={idx}
                    value={item.value}
                  >
                    {item.text}
                  </MenuItem>
                )
              })}
            </TextField>
          </Grid>
        </Grid>
        <Grid padding={2}>
          <TextField
            disabled={readOnly}
            variant="outlined"
            label={t("template.activity.description")}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth={true}
          />
        </Grid>
        <Grid padding={2}>
          <Divider />
        </Grid>
        <Grid padding={2}>
          {["SINGLE", "MULTIPLE"].includes(type) && (
            <SelectionTemplate
              params={params}
              updateParams={setParams}
              readOnly={readOnly}
            />
          )}
          {["RANGE", "SLIDER"].includes(type) && (
            <RangeTemplate
              params={params}
              updateParams={setParams}
              readOnly={readOnly}
            />
          )}
        </Grid>
      </Paper>
    </Grid>
  )
}

export default BlockTemplate;