import React from 'react';
import { Slider, styled } from '@mui/material';

const CustomSlider = styled(Slider)(({ theme, marks }) => {
  // Generate styles for each segment between marks
  const generateMarkSegmentStyles = () => {
    const styles = {};
    
    if (!marks || marks.length < 2) return styles;

    // Create the gradient string for the rail
    const gradientString = marks.reduce((acc, mark, idx) => {
      if (idx === marks.length - 1) return acc + ')';
      const start = mark.value;
      const end = marks[idx + 1].value;
      const color = mark.color || theme.palette.primary.main;
      return `${acc}${color} ${start}%, ${color} ${end}%${idx < marks.length - 2 ? ', ' : ''}`;
    }, 'linear-gradient(to right, ');

    // Apply the gradient to the rail
    styles[`& .MuiSlider-rail`] = {
      opacity: 1,
      background: gradientString,
      border: 'none',
    };

    // Style for the track (the active part)
    styles[`& .MuiSlider-track`] = {
      border: 'none',
      background: 'transparent', // Make track transparent to show rail gradient
    };

    return styles;
  };

  return {
    height: 8,
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
    },
    '& .MuiSlider-mark': {
      visibility: 'hidden',
    },
    '& .MuiSlider-markLabel': {
      fontSize: 12,
      fontWeight: 500,
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: theme.palette.primary.main,
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
    ...generateMarkSegmentStyles(),
  };
});

const ColoredSlider = ({ marks = [], ...props }) => {
  return (
    <CustomSlider
      marks={marks}
      {...props}
    />
  );
};

export default ColoredSlider; 