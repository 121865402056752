export const timeDisplayStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    marginY: 2
  },
  label: {
    color: 'text.primary',
    mr: 1
  },
  timeBox: {
    border: 1,
    borderColor: 'grey.300',
    borderRadius: 1,
    bgcolor: 'background.paper',
    '&&': {
      padding: '8px 16px !important'
    }
  },
  timeValue: {
    color: 'warning.main',
    fontWeight: 700
  }
};

export const tabStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    mt: 1,
    mb: 2
  },
  radioLabel: {
    mr: 1,
    '& .MuiFormControlLabel-label': {
      color: 'text.primary'
    }
  },
  radioContainer: {
    border: 1,
    borderColor: 'grey.300',
    borderRadius: 1,
    bgcolor: 'background.paper',
    '&&': {
      pr: '8px !important'
    },
    '& .MuiFormControlLabel-root': {
      margin: '0px !important'
    }
  }
};

export const workIntensityStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tooltip: {
    marginLeft: 1,
  },
  outerBox: {
    border: 1,
    borderColor: 'grey.300',
    borderRadius: 1,
    '&&': {
      padding: '12px !important'
    },
  }
};